// barevnost

$custom-palette: (
    primary: get-color(primary),
    secondary: get-color(secondary),
    text: $black,
    background: $white,
    lightgray: $light-gray,
    blue: blue,
    references: #F4F4F4,
    features: #E6F5FF,
    yellow: yellow
);

@each $scope,$color in $custom-palette {
    .color-#{$scope} {
        color: $color;
    }
    .background-#{$scope} {
        background: $color;
    }
}

// paddingy a marginy
$gutters: (
    base: (
        small: 40px,//map-get($grid-column-gutter,small),
        medium: 60px,//map-get($grid-column-gutter,medium),
        large: 80px,//map-get($grid-column-gutter,large),
    ),
    half: (
        small: 20px,//map-get($grid-column-gutter,small) / 2,
        medium: 30px,//map-get($grid-column-gutter,medium) / 2,
        large: 40px,//map-get($grid-column-gutter,large) / 2,
    ),
    quarter: (
        small: 20px,//map-get($grid-column-gutter,small) / 4,
        medium: 15px,//map-get($grid-column-gutter,medium) / 4,
        large: 20px,//map-get($grid-column-gutter,large) / 4,
    ),
    zero: (
        small: 0px,
        medium: 0px,
        large: 0px,
        xlarge: 0px,
    ),
    three-quarters: (
        small: 40px / 4 * 3,
        medium: 40px / 4 * 3,
        large: 40px / 4 * 3,
    )
);
$sides: (top, right, bottom, left);
@each $scope, $vals in $gutters {
    @each $bp,$val in $vals {
        @media (min-width: map-get($breakpoints,$bp)) {
            .padding-#{$scope} {
                padding: $val;
            }
            .margin-#{$scope} {
                margin: $val;
            }
            @each $side in $sides {
                .padding-#{$scope}-#{$side} {
                    padding-#{$side}: $val;
                }
                .margin-#{$scope}-#{$side} {
                    margin-#{$side}: $val;
                }
            }
            .padding-#{$scope}-horizontal {
                padding-left: $val;
                padding-right: $val;
            }
            .padding-#{$scope}-vertical {
                padding-top: $val;
                padding-bottom: $val;
            }
            .margin-#{$scope}-horizontal {
                margin-left: $val;
                margin-right: $val;
            }
            .margin-#{$scope}-vertical {
                margin-top: $val;
                margin-bottom: $val;
            }
        }
    }
}

// velikosti písem
$font-styles: (
    headings: (
        small: (
            font-size: 35px,
            line-height: 38px,
            font-family: "Rubik",
            font-weight: 700,
            // color:red
        ),
        medium: (
            font-size: 35px,
            line-height: 38px,
        ),
        large: (
            font-size: 40px,
            line-height: 45px
        ),
    ),
    subheading: (
        small: (
            // color:green,
            font-size: 24px,
            line-height: 29px,
            font-family: "Rubik",
        ),
    ),
    larger: (
        small: (
            font-size: 24px,
            line-height: 27px,
            
            // color:blue,
            // font-weight:bold,
        ),
    ),
    item-title: (
        small: (
            // color:green,
            font-size: 17px,
            line-height: 20px,
        ),
    ),
);

@each $scope,$bps in $font-styles {
    .font-style-#{$scope} {
        @each $bp,$attrs in $bps {
            @media (min-width: map-get($breakpoints,$bp)) {
                @each $attr,$val in $attrs {
                    #{$attr}: $val;
                }
            }
            
        }
    }
}