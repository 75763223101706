// mixin expanding a responsive list
@mixin expand-list($list){
    @each $breakpoint, $props in $list {
        $scope: map-get($breakpoints,$breakpoint);
        @media (min-width:$scope) {
            @each $prop, $value in $props {
                #{$prop}:#{$value};
            }
        }
    }
}

// mixin expanding a particulat nested list
@mixin expand-list-choice($list,$option) {
    @each $breakpoint,$choices in $list {
        @media (min-width:map-get($breakpoints,$breakpoint)) {
            $props: map-get($choices,$option);
            @each $prop,$value in $props {
                #{$prop}: rem-calc($value);
            }
        }
    }
}

// expand a list into a specified value 
@mixin expand-into-property($list,$property,$aspect:1) {
    @each $breakpoint,$value in $list {
        @media (min-width:map-get($breakpoints,$breakpoint)) {
            #{$property}:$value * $aspect;
        }
    }
}

// mixin outputting a responsive grrid cell
@mixin custom_cell($list) {
    @each $size,$column in $list {
        @media (min-width:map-get($breakpoints,$size)) {
            @include xy-cell($column, true, $grid-margin-gutters, margin, (left,right), $size, false);
        }
    }
}

/** Responzivní sémantická velikost písem
/** Responzivní velikosti písem */
$font-sizes: (
    base: (
        small:15px,
    ),
    big:(
        small: 22px,
        //medium: 30px,
        //xlarge: 32px,

    ),
);

@mixin responsive-font-size($scope){
    @each $bp,$size in map-get($font-sizes,$scope) {
        @media (min-width:map-get($breakpoints,$bp)) {
            font-size: $size !important;
        }
    }
}

/** Čtyřhranný gradient */
@mixin quadrient($tl,$tr,$bl,$br,$trans:#888) {

    background: linear-gradient(to bottom right, $tl, transparent 50%, $bl),
                linear-gradient(to top right, $br, transparent 50%, $tr ) $trans;

    /**
    @supports (background: paint(id, foo)) {
        &.is-loaded {
          background: paint(corners-gradient, $tl, $tr, $br, $bl);
        }
    }
    */

}