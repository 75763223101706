.section {
    
    // obecnosti
    .section-title {
        text-align:center;
        font-weight:bold;
    }

    // konkrétní sekce

    // sekce s obrázkem
    &.imaged {
        background-color: $medium-gray;
        background-size:cover !important;
        position:relative;

        // výplňový gradient
        &.reference {
            @include quadrient( rgb(224, 224, 72), rgb(80, 86, 100), rgb(0, 38, 255), rgb(75, 75, 105) );
        }
        &.intro {
            @include quadrient( rgb(20, 19, 22), rgb(13, 16, 19), rgb(46, 140, 168), rgb(43, 102, 102), rgb(0, 0, 0) );
        }
        &.services {
            @include quadrient( #ADB2B5, #D4E0F5, #B3BBC8, #8E644F, #C39481 );
        }

        &.section-background-bottom {
            background-position: center bottom;
        }
    }

    // fullheight
    &.fullheight {
        $heights: (
            small:80vh,
        );
        @include expand-into-property( $heights, height  );

        display:flex;
        flex-wrap:wrap;
        width:100%;
        > * {
            width:100%;
        }
        .vertical-align-center {
            align-self: center
        }
        .vertical-align-bottom {
            align-self: flex-end;
            vertical-align:bottom;
        }
    }

    // mapa
    &.map {

        background:rgb(184, 221, 177);
        @include quadrient( red, green, blue, yellow );
        width:100%;
        height:70vh;
        border:0 !important;
        .smap {
            height:100%;
        }

    }

    // Intro
    &.intro {
        .arrow-bottom {
            position:absolute;
            bottom:0px;
            width:100%;
            text-align:center;
        }
        .decor-bottom {
            position:absolute;
            bottom:0px !important;
            margin:0px;
            padding:0px;
            right:0px; 
            width:100%;

            img {
                display:block;
                float:right;
                margin:0px;
                padding:0px;
                // width:100%;
                // height:auto;
                // max-width: 80%;
            }
        }
    }
}

// logos
.logos-list {

    margin:0;
    font-size:0;

    .logo-item {
        
        $widths: (
            small: 1,
            mobile: 2,
            pad: 3,
            xlarge: 5,
        );
        @each $bp,$w in $widths {
            @media ( min-width:map-get($breakpoints,$bp) ) {
                width:percentage(1/$w) !important;
            }
        }
        a,img {
            display:block;
            padding:0;margin:0;
        }
        img {
            width:100%;
            height:auto;
        }

    }

}

#footer {
    // border-top:4px solid get-color(primary);
}