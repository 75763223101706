// obyčejný seznam čehosi
.standard-list {
    .standard-list-item {
        // color:brown;
        .wrapper {
            $max-widths: (
                small:250px,
                medium:350px,
                large:400px,
                xlarge: 450px,
            );
            // @include expand-into-property($max-widths,max-width);
            // margin:0 auto;
        }
        
        .standard-title {

        }
        .standard-description {

        }
    }
}

.standard-icon {
    // display:block;
    padding-bottom: 11px;

}

// rozklikávací list
.expandable-list {
    display:block;
    overflow:hidden;

    .decimator {
        // background-clip: content-box;
        padding-bottom:15px;
        cursor:pointer;
    }
    .expander .row {
        justify-content:center;
    }
    .expandator .trigger {
        cursor:pointer;
        text-decoration:underline;
        &:hover {
            text-decoration:none;
        }
    }
    .service-item {
        transition: background-color $speed/2 ease-in-out;
        &:hover {
            background:rgb(249, 250, 250);
        }
    }
    .service-logos {
        text-align:center;
        display:flex;
        flex-wrap:wrap;
        align-items:center;
    }
    .service-logo-holder {
        flex-grow: 1;
        flex-basis:100%;
    }
    .service-supplier-logo {
        // display:inline-block;
        max-width:200px;
        height:auto;
        
    }
    .service-item {
        &.expanded {
            background: rgb(241, 247, 247);
            .decimator {
                background-image:url("/assets/img/icons/arrow_up_lightblue.svg");
                background-repeat:no-repeat;
                background-position: center bottom;
                background-size: 30px;
            }
        }
    }

    $config: (

        small: (
            columns: 1,
            has-max-width: true,
            max-width:pad,
            item-height-exists: false,
            item-height: 320px,
            css-container: (),
            css-item:(
                
            ),
            css-expander-collapsed: (
                background:#dcedf8,
                // color:white,
                //padding:40px,
                overflow:hidden,
                max-height:0px,
                //margin-top: 20px,
                transition: max-height $speed ease-in-out,
            ),
            css-expander-expanded: (
                max-height:900px,
            )
        ),
        pad: (
            columns: 2,
            has-max-width: true,
            max-width: overpad,
            item-height-exists: true,
            item-height: 368px,
            css-container: (),
            css-item:(
                float:left,
                width: percentage(1/2),
                //white-space:0,
                // margin-top:0,
                // display:inline-block,
            ),
            css-expander-collapsed: (
                
            ),
            css-expander-expanded: ()
        ),
        overpad: (
            columns: 2,
            has-max-width: true,
            max-width: medium,
            item-height-exists: true,
            item-height: 310px,
            css-container: (),
            css-item:(
                float:left,
                width: percentage(1/2),
                //white-space:0,
                // margin-top:0,
                // display:inline-block,
            ),
            css-expander-collapsed: (
                
            ),
            css-expander-expanded: ()
        ),
        medium: (
            columns: 2,
            has-max-width: true,
            max-width: postmedium,
            item-height-exists: true,
            item-height: 310px,
            css-container: (),
            css-item:(
                float:left,
                width: percentage(1/2),
                white-space:0,
                margin-top:0,
                // display:inline-block,
            ),
            css-expander-collapsed: (
                
            ),
            css-expander-expanded: ()
        ),
        postmedium: (
            columns: 2,
            has-max-width: true,
            max-width: intermedium,
            item-height-exists: true,
            item-height: 270px,
            css-container: (),
            css-item:(
                float:left,
                width: percentage(1/2),
                white-space:0,
                margin-top:0,
                // display:inline-block,
            ),
            css-expander-collapsed: (
                
            ),
            css-expander-expanded: ()
        ),
        intermedium: (
            columns: 2,
            has-max-width: true,
            max-width: large,
            item-height-exists: true,
            item-height: 250px,
            css-container: (),
            css-item:(
                float:left,
                width: percentage(1/2),
                white-space:0,
                margin-top:0,
                // display:inline-block,
            ),
            css-expander-collapsed: (
                
            ),
            css-expander-expanded: ()
        ),
        large: (
            columns: 3,
            has-max-width: true,
            max-width: xlarge,
            item-height-exists: true,
            item-height: 340px,
            css-container: (),
            css-item:(
                float:left, 
                width: percentage(1/3),
                display:inline-block,
                margin:0,
                white-space:0,

                // padding:0,
            ),
            css-expander-collapsed: (),
            css-expander-expanded: ()
        ),
        xlarge: (
            columns: 3,
            has-max-width: false,
            max-width: xxlarge,
            item-height-exists: true,
            item-height: 270px,
            css-container: (),
            css-item:(
                float:left, 
                width: percentage(1/3),
                display:inline-block,
                margin:0,
                white-space:0,

                // padding:0,
            ),
            css-expander-collapsed: (),
            css-expander-expanded: ()
        ),
        


    );

    // nejpve iterovat breakpointy
    @each $bp,$props in $config {

        @media (min-width:map-get($breakpoints,$bp)) {
            .expandable-list-item {

                // vypsat vlastnosti elementu
                @each $prop,$val in map-get($props,css-item) {
                    #{$prop}: $val;
                }
                // výška elementu pomocí třídy .decimator
                .decimator {
                    // @debug map-get($props, item-height-exists);
                    @if map-get($props, item-height-exists) == true {
                        height: map-get($props,item-height);
                    }
                }
                // výchozí stav expanderu
                .expander {
                    @each $prop,$val in map-get($props,css-expander-collapsed) {
                        #{$prop}: $val;
                    }
                    width: map-get($props,columns) * 100%;
                }
                // rozbalený stav expanderu
                &.expanded {
                    .expander {
                        @each $prop,$val in map-get($props,css-expander-expanded) {
                            #{$prop}: $val;
                        }
                    }
                }
                // zalamování a breakpointy
                // každý Ntý
                $n: map-get($props,columns);
                $nplus: (1 + $n );
                @for $i from 2 to $nplus {
                    $name: #{$n}n+#{$i};
                    &:nth-of-type(#{$n}n+#{$i}) {
                        .expander {
                            @if map-get($props,has-max-width)==true {
                                @media (max-width:map-get($breakpoints,map-get($props,max-width))) {
                                    margin-left: ($i - 1) * -100%;
                                }
                            } @else {
                                margin-left: ($i - 1) * -100%;   
                            }
                        }
                    }   
                } // end forloop
                // každý N+1tý
                
                &:nth-of-type(#{ $n }n+#{ $nplus }) {
                    @if map-get($props,has-max-width)==true {
                        @media (max-width:map-get($breakpoints,map-get($props,max-width))) {
                            clear:left;
                        }
                    } @else {
                        clear:left;                    
                    }
                }
            }
        }

        

    }
    
}

// řádkový seznam prvků
.inline-listing {
    list-style-type:none;
    .inline-item {
        display:inline-block;
    }
}

// seznam kontaktů
.contact-list {

}