$widths: (
    large: 1000px,
    xlarge: 1200px,
);
@each $bp, $val in $widths {

    @media (min-width: map-get($breakpoints,$bp)) {

        .row {
            max-width: $val;
        }
        .mw {
            max-width: rem-calc($val) !important;
            margin:0 auto;
        }
            
    }

}



.row-smaller {
    $widths: (
        large: 900,
        // xlarge: 1200,
        // xxlarge, 1500,
    );
    @each $bp, $val in $widths {
        @media (min-width: map-get($breakpoints,$bp)) {
            max-width: rem-calc($val);
        }
    }
}

.row-inline {
    display:block;
}

.row-stretched {
    width: 100%;
    max-width:100% !important;
}
.row-narrow {
    margin:0 !important;
}
.row-centered {
    justify-content:center;
}

.text-uppercase {
    text-transform: uppercase;
}
.text-bold {
    font-weight: $global-weight-bold;
}



.text-center {
    text-align:center;
}
.top-bar {
    ul {
        a {
            color: black;
            &.is-active {
                color:get-color(primary);
            }
        }
    }
}
.button-holder {
    flex-grow:1;
    text-align:right;
}

#offCanvas {
    color:white;
    a {
        color:$black;
        &:hover {
            // color:blue;
        }
    }
}

.backgrounder {
    height:150px;
    background-repeat:repeat-x;
    background-position:bottom left;
    
    
    .person {
        height:100%;
        width:1000%;
        background-position:bottom left;
        background-repeat:no-repeat;
    }
}


.readmore {
    overflow:hidden;
    transition: height $speed ease-in-out;

    &.about-text {
        $heights: (
            small: 200px,
            medium: 300px,
            large: 200px,
        );
        @include expand-into-property($heights,max-height);
    }
    &.contacts {
        $heights: (
            small: 200px,
            medium: 300px,
            large: 200px,
        );
        @include expand-into-property($heights,max-height);
    }
    
}
.readmore-js-trigger {
    text-align:center;
    color: blue;
    padding-top: 20px;
    transition:opacity .1 ease-in-out;
    background:$white;
    height: 100px;

    span {
        cursor:pointer;
        text-transform:uppercase;
    }

    &.more {
        -webkit-box-shadow: 0px -30px 50px -40px rgba(0,0,0,0.75);  
        -moz-box-shadow: 0px -30px 50px -40px rgba(0,0,0,0.75);
        box-shadow: 0px -30px 50px -40px rgba(0,0,0,0.75);
    }
}

.close-button {
    z-index:999;
    $top: (
        small: (
            top:20px,
            right: 30px,
            font-size:40px
        ),
    );
    @include expand-list($top);
    &:focus {
        color:$black;
        &:hover {
            color:blue;
        }
    }
}

.offcanvas-menu {
    $top: (
        small: (
            top:80px,
            text-align:center,
        ),
    );
    @include expand-list($top);
}
#offCanvas {
    a,button {
        // transition: color $speed/3 ease-in-out;
    }
}

@keyframes flock {
    from {
        transform:translate(0,-5px);
    }
    to {
        transform:translate(0,5px);
    }
}

#intro-arrow {
    cursor:pointer;
    // transition: transform $speed/2 ease-in-out;
    animation-duration:$speed;
    animation-name: flock;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    &:hover {
       //  transform: translate(0,5px);
    }
}

#quick-contacts {
    ul.menu.align-right {
        @media (max-width:map-get($breakpoints,overpad)) {
            justify-content:center;
        }
    }
}

#footer {
    a:hover {
        color:$white;
        text-decoration:underline;
    }
}

.and-more-text {

    width:100%;
    flex-grow:1;

}